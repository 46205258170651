$(document).on("turbolinks:load", () => {
  const messageOption = {
    closeButton: true,
    debug: true,
    progressBar: true,
    positionClass: "toast-top-right",
    showDuration: "400",
    hideDuration: "1000",
    timeOut: "7000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "slideDown",
    hideMethod: "slideUp",
  };
  $.each($(".alert-message"), (_, element) => {
    const message = $(element).text();
    const type = $(element).data("type");
    switch (type) {
      case "notice":
        toastr.success(message, "", messageOption);
        break;
      case "alert":
        toastr.error(message, "", messageOption);
        break;
      default:
        toastr.success(message, "", messageOption);
    }
  });

  // Upload photo
  const uploader = "input[type='file'] ";
  const placeholder = $("#uploadfile-1-preview");
  $("#btn-upload").on("click", () => {
    $(uploader).trigger("click");
  });

  $(uploader).on("change", function (e) {
    var files = e.target.files;
    if (FileReader && files && files.length) {
      reader = new FileReader();
      if (reader && placeholder[0]) {
        reader.onload = function () {
          placeholder[0].src = reader.result;
        };
        reader.readAsDataURL(files[0]);
      }
    } else {
      console.log("Your browser doesn't support file upload!");
    }
  });

  $("#uploadremove").on("click", () => {
    const imageSrc = $("#avatar-photo-placeholde").attr("src");
    placeholder[0].src = imageSrc;

    $(uploader).val("");
  });

  // dart mode
  const storedTheme = localStorage.getItem("theme");

  const getPreferredTheme = () => {
    if (storedTheme) {
      return storedTheme;
    }
    return window.matchMedia("(prefers-color-scheme: light)").matches
      ? "light"
      : "light";
  };

  const setTheme = function (theme) {
    if (
      theme === "auto" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.setAttribute("data-bs-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-bs-theme", theme);
    }
  };

  setTheme(getPreferredTheme());

  // window.addEventListener("DOMContentLoaded", () => {
  var el = document.querySelector(".theme-icon-active");
  if (el != "undefined" && el != null) {
    const showActiveTheme = (theme) => {
      const activeThemeIcon = document.querySelector(".theme-icon-active use");
      const btnToActive = document.querySelector(
        `[data-bs-theme-value="${theme}"]`
      );
      const svgOfActiveBtn = btnToActive
        .querySelector(".mode-switch use")
        .getAttribute("href");

      document.querySelectorAll("[data-bs-theme-value]").forEach((element) => {
        element.classList.remove("active");
      });

      btnToActive.classList.add("active");
      activeThemeIcon.setAttribute("href", svgOfActiveBtn);
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => {
        if (storedTheme !== "light" || storedTheme !== "dark") {
          setTheme(getPreferredTheme());
        }
      });

    showActiveTheme(getPreferredTheme());

    document.querySelectorAll("[data-bs-theme-value]").forEach((toggle) => {
      toggle.addEventListener("click", () => {
        const theme = toggle.getAttribute("data-bs-theme-value");
        localStorage.setItem("theme", theme);
        setTheme(theme);
        showActiveTheme(theme);
      });
    });
  }
  // });
  $("a[disabled='disabled']").on("click", function (e) {
    e.stopImmediatePropagation();
    e.preventDefault();
  });
  // bootstrap datepicker
  $.fn.datepicker.defaults.format = "yyyy-mm-dd";
  $(".datepicker").datepicker();

  // Bootstrap popover
  $('[data-toggle="popover"]').popover({
    container: "body",
    html: true,
    trigger: "hover",
  });

  $("#copyToClipboard").on("click", function (e) {
    // Get the text field
    const copyText = this.dataset.url;

    // Select the text field
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText);

    // Alert the copied text
    alert("Copied the text: " + copyText);
  });
});
