// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "cocoon-js-vanilla";
import $ from "jquery";
window.jQuery = $;
window.$ = $;

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import "jQuery-QueryBuilder";
import "daterangepicker";
var $script = require("scriptjs");

$script(["jquery", "daterangepicker"], function () {
  $('input[name="daterange"]').daterangepicker(
    {
      opens: "left",
      locale: {
        format: "DD/MM/YYYY",
      },
    },
    function (start, end, label) {
      this.element.val(
        start.format("YYYY-MM-DD") +
          " 00:00:00" +
          ", " +
          end.format("YYYY-MM-DD") +
          " 23:59:59"
      );
      this.element.attr(
        "value",
        start.format("YYYY-MM-DD") +
          " 00:00:00" +
          ", " +
          end.format("YYYY-MM-DD") +
          " 23:59:59"
      );

      if (this.element.val().length > 0) {
        $.get(
          $("#scholars-search").attr("action"),
          $("#scholars-search").serialize(),
          null,
          "script"
        );
      } else if (e.target.value.length == 0)
        $.get($("#scholars-search").attr("action"), null, null, "script");

      console.log(
        "A new date selection was made: " +
          start.format("YYYY-MM-DD") +
          " to " +
          end.format("YYYY-MM-DD")
      );
    }
  );
});

import "../controllers";
// require("@nathanvda/cocoon");

import "../stylesheets/application";
import "bootstrap"; // css
import "bootstrap-icons/font/bootstrap-icons.css";
import "overlayscrollbars/css/OverlayScrollbars.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "apexcharts/dist/apexcharts.css";
import "jQuery-QueryBuilder/dist/css/query-builder.default.css";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";

import toastr from "toastr/toastr";
window.toastr = toastr;
import "toastr/toastr.scss";

import "./common.js";

// $("head").append(
//   $(
//     '<script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>'
//   )
// );
